// extracted by mini-css-extract-plugin
export var blockTitle = "Styles-module--blockTitle--2c78f";
export var btnInner = "Styles-module--btnInner--501c8";
export var cont = "Styles-module--cont--83a57";
export var description = "Styles-module--description--d5c73";
export var emailBtn = "Styles-module--emailBtn--98ee8";
export var fixContentScroll = "Styles-module--fixContentScroll--a2047";
export var h3 = "Styles-module--h3--91807";
export var headList = "Styles-module--headList--a633a";
export var header = "Styles-module--header--3d038";
export var headerButton = "Styles-module--headerButton--9eabf";
export var headerButtonMd = "Styles-module--headerButtonMd--6be6a";
export var headerLeft = "Styles-module--headerLeft--8d9fc";
export var icon = "Styles-module--icon--58e6d";
export var list = "Styles-module--list--4a249";
export var section = "Styles-module--section--9932b";
export var sideMenuFix = "Styles-module--sideMenuFix--c9962";
export var smallDescription = "Styles-module--smallDescription--3b90d";
export var title = "Styles-module--title--40961";